.dropdown {
  position: relative;
  width: 204px;
  box-sizing: border-box;
  height: 34px;
  background-color: white;
  border: 2px solid #515FF3;
  padding: 0 8px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 10px 10px 10px; }
  @media (max-width: 480px) {
    .dropdown {
      width: 100% !important;
      margin: 20px 0; } }
  .dropdown .fake-input {
    position: absolute;
    z-index: 100;
    left: -3px;
    top: 0;
    opacity: 0;
    width: calc(100% + 3px);
    height: 100%;
    font-size: 1em; }
  .dropdown label.dropdown-label {
    position: absolute;
    left: 8px;
    top: -50%;
    transform: translateY(-50%);
    transition: all .3s ease;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 700;
    font-style: normal;
    font-size: 1em !important;
    color: #333333; }
  .dropdown .dropdown-placeholder {
    position: absolute;
    top: -50%;
    transform: translateY(-50%);
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 400;
    font-style: normal;
    font-size: 1em;
    color: grey;
    transition: all .3s ease;
    user-select: none;
    opacity: 0; }
  .dropdown.empty .dropdown-placeholder {
    top: 50%;
    opacity: 1; }
  .dropdown .displayed-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%; }
  .dropdown .arrow {
    position: absolute;
    right: 12px;
    width: 11px;
    height: 11px; }
    .dropdown .arrow::after {
      position: absolute;
      content: "";
      width: 11px;
      height: 11px;
      top: 0;
      left: 0;
      border-bottom: 1.5px solid black;
      border-right: 1.5px solid black;
      transform: translateY(-3px) rotate(45deg);
      transition: all .3s ease; }
  .dropdown .list {
    position: absolute;
    z-index: 150;
    display: none;
    flex-direction: column;
    width: 100%;
    max-height: 160px;
    overflow-y: scroll;
    top: 30px;
    left: -2px;
    background-color: white;
    border: 2px solid #515FF3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 400;
    font-style: normal;
    font-size: 1em; }
    .dropdown .list .option {
      width: 100%;
      min-height: 40px;
      padding: 0 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      .dropdown .list .option:not(:last-child) {
        border-bottom: 1px solid #515FF3; }
      .dropdown .list .option:hover {
        background-color: #ddd; }
      .dropdown .list .option.selected {
        background-color: #eee; }
  .dropdown.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
    .dropdown.active .arrow::after {
      transform: translateY(3px) rotate(-135deg); }
    .dropdown.active .list {
      display: flex; }
