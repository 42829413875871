.appointment-info-page .appointment-info-content {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .appointment-info-page .appointment-info-content table {
    border-collapse: collapse; }
    .appointment-info-page .appointment-info-content table tr:last-child td {
      border-bottom: none; }
    .appointment-info-page .appointment-info-content table tr td {
      border-bottom: 1px solid grey; }
      .appointment-info-page .appointment-info-content table tr td:first-child {
        border-right: 1px solid grey; }
  .appointment-info-page .appointment-info-content > table {
    width: 40%; }
    @media print {
      .appointment-info-page .appointment-info-content > table {
        width: 80%; } }
  .appointment-info-page .appointment-info-content td {
    vertical-align: top;
    padding: 5px;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 400;
    font-style: normal;
    font-size: 1em; }
    .appointment-info-page .appointment-info-content td:last-child {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: 700;
      font-style: normal;
      font-size: 1em !important; }
    .appointment-info-page .appointment-info-content td table {
      width: 100%; }

.appointment-info-page .button-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
