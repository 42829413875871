.patient-actions {
    &-page {
        width: 100%;

        & > * + * {
            margin-top: 16px;
        }

        &__search-label {
            display: flex;
            flex-direction: column;
            font-weight: bold;
        }

        &__search-input {
            border: 1px solid gray;
            font-size: 16px;
        }
    }

    &-table {
        width: 100%;
        display: flex;
        flex-direction: column;

        &__head {
            display: flex;
            flex-direction: column;
        }

        &__body {
            display: flex;
            flex-direction: column;
        }

        &__row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &:nth-child(2n) {
                background-color: #eee;
            }

            &_head {
                border-bottom: solid 1px black;
            }
        }

        &__cell {
            display: block;
            padding: 8px;
            text-align: left;

            &:nth-child(1) {
                width: 15%;
            }

            &:nth-child(2) {
                width: 15%;
            }

            &:nth-child(3) {
                width: 30%;
            }

            &:nth-child(4) {
                width: 15%;
            }

            &:nth-child(5) {
                width: 25%;
            }
        }

        &__appointment {
            color: green;
        }

        &__cancel {
            color: red;
        }
    }
}
