.calendar {
  position: relative;
  width: 300px; }
  .calendar .month-input {
    display: flex;
    justify-content: center;
    align-items: center; }
  .calendar .month-selector {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .calendar .month-selector .arrow {
      width: 25px;
      height: 25px;
      position: relative; }
      .calendar .month-selector .arrow::before {
        content: "";
        width: 10px;
        height: 10px;
        top: 7.5px;
        left: 7.5px;
        position: absolute;
        border-bottom: 1px solid black;
        border-left: 1px solid black; }
      .calendar .month-selector .arrow.left::before {
        transform: translateX(3px) rotate(45deg); }
      .calendar .month-selector .arrow.right::before {
        transform: translateX(-3px) rotate(-135deg); }
    .calendar .month-selector .selected-month {
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: capitalize;
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: 700;
      font-style: normal;
      font-size: 1em !important; }
