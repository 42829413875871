.patient-card {
  padding: 20px;
  height: 80%;
  overflow: scroll; }
  .patient-card h2 {
    margin: 0; }
  .patient-card table {
    margin: 10px 0;
    border-collapse: collapse; }
    .patient-card table td {
      padding: 5px;
      border-bottom: 2px solid #515FF3;
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: 400;
      font-style: normal;
      font-size: 1em; }
    .patient-card table.patient-info td:last-child {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: 700;
      font-style: normal;
      font-size: 1em !important; }
    .patient-card table.patient-seances .header td {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: 700;
      font-style: normal;
      font-size: 1em !important; }
