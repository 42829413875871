@charset "UTF-8";
.schedule-generator {
  padding: 20px;
  min-width: 480px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }
  .schedule-generator .block-with-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .schedule-generator label.block-label {
    margin: 10px;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 700;
    font-style: normal;
    font-size: 1em !important; }
    .schedule-generator label.block-label.time {
      margin-bottom: 0; }
  .schedule-generator .block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .schedule-generator .block.first {
      margin-top: 10px; }
  .schedule-generator .work-time-input {
    margin-top: 5px;
    width: 50px; }
    .schedule-generator .work-time-input.hour::after {
      content: "ч"; }
    .schedule-generator .work-time-input.minute::after {
      content: "м"; }
    .schedule-generator .work-time-input:after {
      position: absolute;
      right: 5px;
      color: #515FF3; }
