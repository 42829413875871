.patients-page {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .patients-page .patients-filter .page-selector {
    display: flex;
    justify-content: center;
    align-items: center; }
    .patients-page .patients-filter .page-selector .page-label {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: 400;
      font-style: normal;
      font-size: 1em;
      margin-right: 5px; }
    .patients-page .patients-filter .page-selector .page-changer {
      width: 25px;
      height: 25px;
      position: relative; }
      .patients-page .patients-filter .page-selector .page-changer::before {
        content: "";
        width: 10px;
        height: 10px;
        top: 7.5px;
        left: 7.5px;
        position: absolute;
        border-bottom: 1px solid black;
        border-left: 1px solid black; }
      .patients-page .patients-filter .page-selector .page-changer.dec::before {
        transform: translateX(3px) rotate(45deg); }
      .patients-page .patients-filter .page-selector .page-changer.inc::before {
        transform: translateX(-3px) rotate(-135deg); }
    .patients-page .patients-filter .page-selector .page-number {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: 700;
      font-style: normal;
      font-size: 1em !important;
      padding: 5px; }
  .patients-page .patients-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    .patients-page .patients-table .table-row {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      height: 40px;
      margin-bottom: 10px; }
      .patients-page .patients-table .table-row.header {
        font-family: Arial, Helvetica, sans-serif !important;
        font-weight: 700;
        font-style: normal;
        font-size: 20px !important; }
      .patients-page .patients-table .table-row:not(.header) {
        border-top: solid #515FF3 2px; }
      .patients-page .patients-table .table-row .table-cell {
        padding: 20px 10px 10px 10px; }
        .patients-page .patients-table .table-row .table-cell:nth-child(1) {
          width: 300px; }
        .patients-page .patients-table .table-row .table-cell:nth-child(2) {
          width: 200px; }
        .patients-page .patients-table .table-row .table-cell:nth-child(3) {
          width: 30px; }
