.schedule-page .menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.schedule-page .schedule-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }
  .schedule-page .schedule-table .table-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 40px;
    margin-bottom: 10px; }
    .schedule-page .schedule-table .table-row.header {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: 700;
      font-style: normal;
      font-size: 20px !important; }
      .schedule-page .schedule-table .table-row.header .table-cell.sorted {
        position: relative; }
        .schedule-page .schedule-table .table-row.header .table-cell.sorted::after {
          position: absolute;
          top: 30px;
          margin-left: 5px;
          content: "";
          width: 7px;
          height: 7px;
          transform: rotate(45deg) translateY(-50%);
          border-right: 2px solid #515FF3;
          border-bottom: 2px solid #515FF3; }
    .schedule-page .schedule-table .table-row:not(.header) {
      border-top: solid #515FF3 2px; }
    .schedule-page .schedule-table .table-row .table-cell {
      padding: 20px 10px 10px 10px; }
      .schedule-page .schedule-table .table-row .table-cell:nth-child(1) {
        width: 120px; }
      .schedule-page .schedule-table .table-row .table-cell:nth-child(2) {
        width: 250px; }
      .schedule-page .schedule-table .table-row .table-cell:nth-child(3) {
        width: 250px; }
      .schedule-page .schedule-table .table-row .table-cell:nth-child(4) {
        width: 30px; }
      .schedule-page .schedule-table .table-row .table-cell.clickable {
        text-decoration: underline; }
