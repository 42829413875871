.switch {
  margin: 10px; }
  .switch input {
    display: none; }
  .switch .container {
    position: relative;
    width: 45px;
    height: 25px;
    border-radius: 4px;
    border: #515FF3 solid 2px;
    background-color: #eeeeee;
    transition: all .3s ease; }
    .switch .container .grip {
      position: absolute;
      top: 0;
      left: 0;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      background-color: white;
      box-shadow: 2.5px 0 2.5px rgba(0, 0, 0, 0.25);
      transition: all .3s ease; }
  .switch.active .container {
    background-color: #515FF3; }
    .switch.active .container .grip {
      left: 20px;
      box-shadow: -2.5px 0 2.5px rgba(0, 0, 0, 0.25); }
