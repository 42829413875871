.text-input {
  position: relative;
  height: 30px;
  border-radius: 4px;
  border: solid 2px #515FF3;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease;
  margin: 20px 10px 10px 10px;
  cursor: text; }
  @media (max-width: 480px) {
    .text-input {
      width: 100%; } }
  .text-input.focused {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
  .text-input label.input-label {
    position: absolute;
    left: 3px;
    bottom: 35px;
    padding: 2px 5px;
    transition: all .3s ease;
    border-radius: 4px;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 700;
    font-style: normal;
    font-size: 1em !important;
    color: #333333;
    user-select: none; }
  .text-input .input-placeholder {
    position: absolute;
    left: 8px;
    bottom: 7px;
    transition: all .3s ease;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 400;
    font-style: normal;
    font-size: 1em;
    color: grey;
    user-select: none; }
  .text-input.focused .input-placeholder, .text-input.not-empty .input-placeholder {
    bottom: 35px;
    opacity: 0; }
  .text-input.focused label.input-label {
    background-color: #515FF3;
    color: white; }
  .text-input.invalid {
    border-color: #ff5555; }
    .text-input.invalid label.input-label {
      background-color: #ff5555;
      color: white; }
  .text-input input {
    width: calc(100% - 20px);
    height: calc(100% - 10px);
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 400;
    font-style: normal;
    font-size: 1em; }
