.action-menu {
  position: relative; }
  .action-menu .trigger {
    width: 20px;
    height: 20px;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 700;
    font-style: normal;
    font-size: 2em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../../../images/pencil-alt.svg) no-repeat;
    background-size: contain;
    user-select: none; }
  .action-menu .menu-popup {
    position: absolute;
    padding: 10px;
    left: 35px;
    bottom: -10px;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
    .action-menu .menu-popup::before {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      transform: rotate(45deg) translate(-50%, -50%);
      left: -5px;
      bottom: 10px;
      background-color: white; }
