body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
  color: #333333; }
  body.no-scroll {
    overflow: hidden;
    -webkit-overflow-scrolling: touch; }

h1, h2, h3 {
  margin: 0; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.clickable {
  cursor: pointer; }

input {
  border: none;
  outline: none; }

.warning-text {
  color: #ffae42; }

.danger-text {
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 700;
  font-style: normal;
  font-size: 1em !important;
  color: #ff5555; }

button, .button {
  border: none;
  cursor: pointer;
  margin: 5px;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 700;
  font-style: normal;
  font-size: 1em !important;
  padding: 0;
  text-decoration: none;
  user-select: none; }
  @media print {
    button, .button {
      display: none; } }
  button.main, .button.main {
    width: 150px;
    height: 50px;
    border: solid 2px #515FF3;
    border-radius: 4px;
    transition: all .3s ease;
    background-color: #515FF3;
    color: white; }
    button.main.danger, .button.main.danger {
      background-color: #ff5555;
      border-color: #ff5555; }
    button.main.warning, .button.main.warning {
      background-color: #ffae42;
      border-color: #ffae42; }
    button.main:not(:disabled):hover, .button.main:not(:disabled):hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
      background-color: rgba(81, 95, 243, 0.9); }
      button.main:not(:disabled):hover.danger, .button.main:not(:disabled):hover.danger {
        background-color: rgba(255, 85, 85, 0.9); }
      button.main:not(:disabled):hover.warning, .button.main:not(:disabled):hover.warning {
        background-color: rgba(255, 174, 66, 0.9); }
    button.main:not(:disabled):active, .button.main:not(:disabled):active {
      box-shadow: none; }
    button.main:disabled, .button.main:disabled {
      opacity: .5;
      cursor: not-allowed; }
  button.secondary, .button.secondary {
    color: #515FF3;
    background: none;
    border: 2px solid transparent;
    padding: 5px;
    transition: all .3s ease;
    border-radius: 4px; }
    button.secondary.danger, .button.secondary.danger {
      color: #ff5555; }
    button.secondary.warning, .button.secondary.warning {
      color: #ffae42; }
    button.secondary:not(:disabled):hover, .button.secondary:not(:disabled):hover {
      border: solid 2px #515FF3;
      border-radius: 4px; }
      button.secondary:not(:disabled):hover.danger, .button.secondary:not(:disabled):hover.danger {
        border-color: #ff5555; }
      button.secondary:not(:disabled):hover.warning, .button.secondary:not(:disabled):hover.warning {
        border-color: #ffae42; }
    button.secondary:disabled, .button.secondary:disabled {
      opacity: .5;
      cursor: not-allowed; }
