.cancel-appointment {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
  box-sizing: border-box;
  min-width: 100px;
  min-height: 100px; }
  .cancel-appointment__text {
    margin: 0; }
  .cancel-appointment__buttons {
    display: flex;
    flex-direction: row;
    gap: 16px; }
