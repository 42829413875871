.seance-selector {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }

.no-doctors {
  display: flex;
  flex-direction: column;
  gap: 8px; }
  .no-doctors__header {
    margin: 0; }
  .no-doctors__text {
    margin: 0; }
