.modal-container {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; }
  .modal-container .modal-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: fade .3s ease forwards; }
  .modal-container .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    animation: upscale .3s ease forwards; }
    @media (max-width: 480px) {
      .modal-container .modal-content {
        width: 90%;
        max-height: 80%;
        overflow: scroll; } }

@keyframes fade {
  0% {
    background-color: rgba(0, 0, 0, 0); }
  100% {
    background-color: rgba(0, 0, 0, 0.5); } }

@keyframes upscale {
  0% {
    transform: translate(-50%, -50%) scale(0.5); }
  100% {
    transform: translate(-50%, -50%) scale(1); } }
