.reg-form h1, .reg-form h2 {
  margin: 10px 0;
  text-align: center; }
  .reg-form h1:first-child, .reg-form h2:first-child {
    margin-top: 0; }

.reg-form .block-label {
  width: 100%;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 700;
  font-style: normal;
  font-size: 1em !important;
  text-align: center;
  padding-left: 20px; }
  @media (max-width: 480px) {
    .reg-form .block-label {
      text-align: left; } }
  .reg-form .block-label.required::after {
    content: "*";
    color: #ff5555; }

.reg-form .block {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (min-width: 801px) {
    .reg-form .block {
      flex-direction: row; } }
  @media (max-width: 480px) {
    .reg-form .block {
      align-items: center; } }
  .reg-form .block.centered {
    align-items: center;
    justify-content: center; }

.reg-form button.send {
  margin-top: 10px; }

.reg-form .warning-window {
  border: solid 2px #515FF3;
  border-radius: 4px;
  padding: 0 20px;
  background-color: #eeeeee; }
  .reg-form .warning-window .title {
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 700;
    font-style: normal;
    font-size: 1em !important;
    color: #ff5555; }
  .reg-form .warning-window .text, .reg-form .warning-window .list {
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 400;
    font-style: normal;
    font-size: 1em;
    line-height: 1.4em; }
  .reg-form .warning-window .text {
    margin-bottom: 0; }
  .reg-form .warning-window .list {
    margin-top: 0; }

.reg-info {
  padding: 20px; }
  .reg-info .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .reg-info .header .close-button {
      width: 20px;
      height: 20px;
      background: url(../../images/close.svg) no-repeat;
      background-size: contain; }
