.month {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }
  .month .week {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; }
    .month .week:first-child {
      background-color: #515FF3;
      border-radius: 4px; }
    .month .week .weekday {
      width: 35px;
      height: 20px;
      border: solid 2px rgba(0, 0, 0, 0);
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: 700;
      font-style: normal;
      font-size: 0.7em !important;
      color: white; }
    .month .week .day {
      width: 25px;
      height: 25px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 2px rgba(0, 0, 0, 0);
      border-radius: 4px;
      transition: border-color .3s ease; }
      .month .week .day:not(.placeholder):not(.disabled):hover {
        border-color: #515FF3; }
      .month .week .day.selected:not(.disabled) {
        background-color: #515FF3;
        color: white; }
      .month .week .day.disabled {
        opacity: 0.25;
        color: #515FF3; }
