.doctors-page .doctors-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }
  .doctors-page .doctors-table .table-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 40px;
    margin-bottom: 10px; }
    .doctors-page .doctors-table .table-row.header {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: 700;
      font-style: normal;
      font-size: 20px !important; }
    .doctors-page .doctors-table .table-row:not(.header) {
      border-top: solid #515FF3 2px; }
    .doctors-page .doctors-table .table-row .table-cell {
      padding: 20px 10px 10px 10px; }
      .doctors-page .doctors-table .table-row .table-cell:nth-child(1) {
        width: 250px; }
      .doctors-page .doctors-table .table-row .table-cell:nth-child(2) {
        width: 250px; }
      .doctors-page .doctors-table .table-row .table-cell:nth-child(3) {
        width: 30px; }
      .doctors-page .doctors-table .table-row .table-cell.clickable {
        text-decoration: underline; }
