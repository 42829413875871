.app {
  width: 90%;
  padding: 20px;
  margin: auto;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }
  @media print {
    .app {
      box-shadow: none; } }
  .app .page-content {
    width: 100%;
    margin: auto; }
    @media (min-width: 801px) {
      .app .page-content {
        width: 70%; } }
