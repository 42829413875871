.loader-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000; }

.loader {
  width: 40px;
  height: 40px;
  position: relative; }
  .loader .double-bounce1, .loader .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #515FF3;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-bounce 2.0s infinite ease-in-out; }
  .loader .double-bounce2 {
    animation-delay: -1.0s; }

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }
