.datepicker-input {
  padding: 0 10px;
  margin: 20px 0 10px 0; }
  @media (max-width: 480px) {
    .datepicker-input {
      width: 100% !important;
      padding: 0; } }
  .datepicker-input .trigger-button {
    position: relative;
    height: 34px;
    border: solid 2px #515FF3;
    border-radius: 4px;
    background-color: white;
    font-weight: 600;
    width: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all .3s ease;
    padding-left: 10px;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 400;
    font-style: normal;
    font-size: 1em; }
    .datepicker-input .trigger-button::after {
      content: "";
      position: absolute;
      right: 1px;
      bottom: 5px;
      background: url(../../../images/calendar.svg) no-repeat;
      background-size: contain;
      width: 20px;
      height: 20px; }
    @media (max-width: 480px) {
      .datepicker-input .trigger-button {
        width: 100% !important; } }
    .datepicker-input .trigger-button label.datepicker-label {
      position: absolute;
      left: 10px;
      bottom: 35px;
      color: #333333;
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: 700;
      font-style: normal;
      font-size: 1em !important;
      padding: 2px 5px;
      transition: all .3s ease;
      border-radius: 4px;
      user-select: none; }
      .datepicker-input .trigger-button label.datepicker-label.required::after {
        content: "*";
        color: #ff5555; }
    .datepicker-input .trigger-button .datepicker-placeholder {
      position: absolute;
      left: 8px;
      bottom: 7px;
      transition: all .3s ease;
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: 400;
      font-style: normal;
      font-size: 1em;
      color: grey;
      user-select: none; }
    .datepicker-input .trigger-button.focused .datepicker-placeholder, .datepicker-input .trigger-button.not-empty .datepicker-placeholder {
      bottom: 35px;
      opacity: 0; }
    .datepicker-input .trigger-button input {
      width: calc(100% - 10px);
      height: calc(100% - 10px); }
    .datepicker-input .trigger-button.invalid {
      border-color: #ff5555; }
      .datepicker-input .trigger-button.invalid label.datepicker-label {
        background-color: #ff5555;
        color: white; }

.datepicker-modal .close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 20px;
  height: 20px;
  background: url(../../../images/close.svg) no-repeat;
  background-size: contain; }

.datepicker-modal h2 {
  margin: 10px;
  text-align: center; }
