.nav-bar {
  position: fixed;
  width: 90%;
  height: 60px;
  left: calc(5% - 20px);
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2500;
  animation: slide-down .3s ease forwards; }
  @media print {
    .nav-bar {
      display: none; } }
  .nav-bar .user-info {
    width: 25%; }
  .nav-bar .menu {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .nav-bar .menu .nav-link {
      color: #515FF3;
      background-color: white;
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: 400;
      font-style: normal;
      font-size: 1em;
      width: 120px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      transition: all .3s ease; }
      .nav-bar .menu .nav-link:hover {
        background-color: #eeeeee; }
      .nav-bar .menu .nav-link.active {
        background-color: #515FF3;
        color: white; }
  .nav-bar .logout {
    width: 25%;
    display: flex;
    justify-content: flex-end; }
  .nav-bar-placeholder {
    width: 100%;
    height: 60px; }

@keyframes slide-down {
  0% {
    top: -80px; }
  100% {
    top: 0px; } }
